import React from 'react';
import './style.css'; // Asegúrate de que la ruta sea correcta

const MaintenanceOverlay = () => {
    return (
        <div className="maintenance-overlay">
            <div className="maintenance-message">
                <h1 style={{color:"white"}}>En Mantenimiento</h1>
                <p>Estamos realizando mejoras. Por favor, vuelva más tarde.</p>
            </div>
        </div>
    );
}

export default MaintenanceOverlay;